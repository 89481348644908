<script lang="ts">
import htmlFromConfig from "../components/menu/HtmlFromConfig.vue";
export default {
    name: 'DonnéesPersonnelles',
    setup() {
        useHead({
            title: 'Données personnelles',
        })
    },
  components: {
    htmlFromConfig,
  },
}
</script>

<template>
  <html-from-config html-name="donnees_personnelles" />
</template>


